import { faTrashCan } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ReactNode } from "react";
import useUtilTranslations from "../../hooks/useUtilTranslations";
import Button from "../Button/Button";
import BaseModal from "../modal/BaseModal";
import styles from "./ConfirmModal.module.scss";

type ConfirmModalProps = {
  header?: ReactNode;
  content: ReactNode;
  description?: ReactNode;
  isModalOpen: boolean;
  destructiveAction?: boolean;
  onClose: (success: boolean) => void;
  activeActionLabel?: string;
};

function ConfirmModal({
  activeActionLabel,
  content,
  description,
  destructiveAction,
  header,
  isModalOpen,
  onClose: _onClose,
}: ConfirmModalProps) {
  const tUtil = useUtilTranslations();
  const onClose = (success?: boolean) => {
    return () => {
      // console.log("Closing modal");
      _onClose(success ?? false);
    };
  };

  return (
    <BaseModal
      className={styles.modal}
      isOpen={isModalOpen}
      onRequestClose={onClose(false)}
    >
      {header && <h3>{header}</h3>}
      <div className={styles.content}>{content}</div>
      {description && <div className={styles.description}>{description}</div>}
      <div className={styles.actions}>
        <Button color="white" onClick={onClose(false)}>
          {tUtil("action.cancel")}
        </Button>
        <Button
          color={destructiveAction ? "red" : undefined}
          onClick={onClose(true)}
        >
          {activeActionLabel ??
            tUtil(destructiveAction ? "action.delete" : "action.confirm")}
          {destructiveAction && (
            <FontAwesomeIcon
              className={styles.destructIcon}
              icon={faTrashCan}
            />
          )}
        </Button>
      </div>
    </BaseModal>
  );
}

export default ConfirmModal;
