import {
  createContext,
  PropsWithChildren,
  ReactNode,
  useCallback,
  useState,
} from "react";
import ConfirmModal from "../components/ConfirmModal/ConfirmModal";

type OpenConfirmModal = (
  content: ReactNode,
  description?: ReactNode,
  opts?: {
    isDestructiveAction?: boolean;
    activeActionLabel?: string;
  }
) => Promise<boolean>;
type ConfirmModalContextValue = {
  openConfirmModal: OpenConfirmModal;
};

export const ConfirmModalContext = createContext<ConfirmModalContextValue>({
  openConfirmModal: async () => false,
});

const initialState: {
  isModalOpen: boolean;
  modalResolve: Function | undefined;
  modalContent: ReactNode;
  modalDescription: ReactNode;
  isDestructiveAction?: boolean;
  activeActionLabel?: string;
} = {
  isModalOpen: false,
  modalContent: undefined,
  modalDescription: undefined,
  modalResolve: undefined,
  isDestructiveAction: undefined,
  activeActionLabel: undefined,
};

export const ConfirmModalProvider = ({ children }: PropsWithChildren<{}>) => {
  const [modalState, setModalState] = useState(initialState);

  const openConfirmModal: OpenConfirmModal = useCallback(
    async (content, description, opts) => {
      return new Promise<boolean>((resolve) => {
        setModalState({
          isModalOpen: true,
          modalContent: content,
          modalDescription: description,
          modalResolve: resolve,
          isDestructiveAction: !!opts?.isDestructiveAction,
          activeActionLabel: opts?.activeActionLabel,
        });
      });
    },
    []
  );

  const onClose = (success: boolean) => {
    setModalState((prevState) => ({
      ...prevState,
      isModalOpen: false,
    }));
    modalState.modalResolve?.(success);
  };

  return (
    <ConfirmModalContext.Provider
      value={{
        openConfirmModal: openConfirmModal,
      }}
    >
      <ConfirmModal
        activeActionLabel={modalState.activeActionLabel}
        content={modalState.modalContent}
        description={modalState.modalDescription}
        destructiveAction={modalState.isDestructiveAction}
        isModalOpen={modalState.isModalOpen}
        onClose={onClose}
      />
      {children}
    </ConfirmModalContext.Provider>
  );
};
